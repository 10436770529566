<!-- 培训详情 -->
<template>
	<div class="train">
		<div>
			<h3 class="tle">{{course.title}}</h3>
			<!-- 讲解课程 -->
			<ul class="us-tm-eye">
				<li>
					<i class="iconfont">&#xe6a4;</i>
					<p>主讲老师-{{course.speaker}}</p>
				</li>
				<li>
					<i class="iconfont">&#xe60a;</i>
					<p>{{course.inputtime}}</p>
				</li>
			</ul>
			<!-- 图文 视频-->
			<h4 class="ctx" v-html="course.subject"></h4>
		</div>
	</div>
</template>

<script>
	import qs from "qs"
	import util from "@/assets/script/util.js"
	import moment from "moment"
	export default {
		data() {
			return {
				course:{}
			}
		},
		created() {
			let id = this.$route.query.id
			let left_id = this.$route.query.left_id
			this.getDetail(id,left_id)
		},
		methods:{
			// 查看详情
			getDetail(id,left_id) {
				let url = this.$api.ecation.InvestmentDetails
				let data = {
					id,
					left_id
				}
				this.$axios.post(
					url,
					qs.stringify(data)
				).then(res=>{
					if(res.code) {
						res.data.inputtime = moment(res.data.inputtime*1000).format("YYYY-MM-DD")
						res.data.subject = util.setContent(res.data.subject)
						this.course = res.data
					}
				})
			}
		},
	}
</script>

<style lang="less" scoped>
	.imgsss {
		margin-top: 30px;
	}
	.video {
		margin: 40px 0 60px 0;
		position: relative;
		transition: all .5s;
		&:hover {
			&::after {
				display: block;
			}
			.icon {
				opacity: 1;
			}
		}
		&::after {
			display: none;
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(0,0,0,.5);
		}
		.icon {
			transition: all .5s;
			opacity: 0;
			background-color: lightgray;
			width: 50px;
			height: 50px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			z-index: 9999;
			i {
				color: #fff;
			}
		}
		
	}
	
	.train {
		box-sizing: border-box;
		.tle {
			line-height: 50px;
			color: rgba(51, 51, 51, 1);
			font-size: 34px;
			font-weight: 700;
			text-align: center;
			margin-top: 50px;
			margin-bottom: 60px;
		}
		.us-tm-eye {
			line-height: 21px;
			color: rgba(153, 153, 153, 1);
			font-size: 14px;
			font-weight: 500;
			display: flex;
			border-bottom: 0.5px solid rgba(235, 236, 239, 1);
			padding-bottom: 20px;
			li {
				display: flex;
				align-items: center;
				margin-right: 40px;
				&:last-child {
					margin-right: 0;
				}
				i {
					margin-right: 5px;
				}
			}
		}
		.audio {
			display: flex;
			justify-content: space-between;
			align-items: center;
			box-sizing: border-box;
			padding: 16px;
			box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
			margin: 60px 0 40px 0;
			.bof {
				flex-shrink: 0;
				width: 40px;
				height: 40px;
				border-radius: 50%;
				background-color: var(--themeColor);
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
			}
			.jd1 {
				box-sizing: border-box;
				padding: 0 30px;
				flex-grow: 1;
				.jindu {
					flex-grow: 1;
					position: relative;
					height: 8px;
					background: rgba(242, 242, 242, 1);
					border-radius: 4px;
					cursor: pointer;
					.jd2 {
						background-color: var(--themeColor);
						width: 0%;
						position: absolute;
						left: 0;
						top: 0;
						bottom: 0;
						border-radius: 4px;
						.circle {
							width: 16px;
							height: 16px;
							border-radius: 50%;
							background-color: #fff;
							position: absolute;
							right: 0;
							transform: translate(50%,-50%);
							display: flex;
							align-items: center;
							justify-content: center;
							top: 50%;
							box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
							.cir {
								width: 8px;
								height: 8px;
								background-color: var(--themeColor);
								border-radius: 50%;
							}
						}
					}
				
				}
			}
			
			.time {
				flex-shrink: 0;
				color: rgba(51, 51, 51, 1);
				font-size: 16px;
				font-weight: 400;
			}
		}
		.tle-cou {
			line-height: 24px;
			color: rgba(51, 51, 51, 1);
			font-size: 16px;
			font-weight: 700;
			margin-bottom: 12px;
		}
		.ctx {
			line-height: 24px;
			color: rgba(102, 102, 102, 1);
			font-size: 16px;
			font-weight: 400;
			padding-bottom: 30px;
			margin-top: 30px;
		}
		.eye {
			color: rgba(153, 153, 153, 1);
			font-size: 14px;
			font-weight: 500;
			line-height: 21px;
			margin-top: 20px;
			i {
				margin-right: 5px;
			}
		}
	}
	@media (max-width:900px) {
		.train {
			padding-top: 10px;
		}
		.video,.audio,.imgsss {
			margin: 20px 0 20px 0;
		}
	}
</style>